import React, {Component} from "react";
import Page from "../components/Page";
import Space from "../components/elements/Space/Space";
import Layout from "../components/Layout";

export default class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Page title='Privacy policy' description='' image='/favicon.png'>
        <Layout>
          {/* navigation space */}
          <Space mobile={15} table={15} desktop={15} />

          <main className='main-content'>
            <section className='section '>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-md-10'>
                    <div className='row text-center'>
                      <div className='col-md-12'>
                        <h2>INFORMACIÓN SOBRE EL TRATAMIENTO DE DATOS PERSONALES DE ACUERDO CON EL ARTÍCULO 13 DEL REGLAMENTO DE LA UE 2016/679</h2>
                      </div>
                    </div>
                    <div className='row mt-8'>
                      <div className='col-md-6  text-center'>
                        <h4>RESPONSABLE DEL TRATAMIENTO</h4>
                      </div>
                      <div className='col-md-6 '>
                        <p className=''>E-leads s.r.l.</p>
                        <a className='mt-2' href='#Titolare'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>PROPÓSITO</h4>
                      </div>
                      <div className='col-md-6 '>
                        <ol style={{paddingLeft: 0}}>
                          <li>Contratación o ejecución de medidas precontractuales, incluida la prestación de un servicio a petición del usuario;</li>
                          <li>Marketing directo;</li>
                          <li>Marketing indirecto;</li>
                          <li>Análisis de tráfico durante la navegación en el sitio;</li>
                          <li>Detección del nivel de satisfacción del cliente y mejora del sitio;</li>
                        </ol>
                        <a className='mt-2' href='#Finalita'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>BASE JURÍDICA</h4>
                      </div>
                      <div className='col-md-6 '>
                        <ol style={{paddingLeft: 0}}>
                          <li>Realización de un contrato o medidas precontractuales, incluyendo la provisión de un servicio solicitado por el usuario;</li>
                          <li>Consentimiento;</li>
                          <li>Interés legítimo.</li>
                        </ol>
                        <a className='mt-2' href='#Base-giuridica'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>DESTINATARIOS O CATEGORIAS DE DESTINATARIOS</h4>
                      </div>
                      <div className='col-md-6 '>
                        <ol style={{paddingLeft: 0}}>
                          <li>Proveedores de servicios de alojamiento web;</li>
                          <li>Proveedores de servicios de análisis estadístico, análisis de tráfico web, marketing y publicidad;</li>
                          <li>Terceros indicados en la información extendida;</li>
                        </ol>
                        <a className='mt-2' href='#Destinatari'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>CATEGORIAS DE DATOS PERSONALES TRATADOS</h4>
                      </div>
                      <div className='col-md-6 '>
                        <ol style={{paddingLeft: 0}}>
                          <li>Datos comunes recopilados automáticamente por el sitio;</li>
                          <li>Datos comunes recopilados a través de formularios en el sitio;</li>
                        </ol>
                        <a className='mt-2' href='#Categorie'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>TRANSFERENCIA DE DATOS A TERCEROS PAISES</h4>
                      </div>
                      <div className='col-md-6 '>
                        <p className=''>Europa</p>
                        <a className='mt-2' href='#Trasferimento'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>CRITERIOS PARA LA CONSERVACION DE DATOS</h4>
                      </div>
                      <div className='col-md-6 '>
                        <p className=''>Criterios utilizados para establecer el período de conservación de los datos.</p>
                        <a className='mt-2' href='#Conservazione'>
                          + información
                        </a>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6  text-center'>
                        <h4>DERECHOS DEL INTERESADO</h4>
                      </div>
                      <div className='col-md-6 '>
                        <p className=''>Acceso, rectificación, supresión de datos y otros derechos indicados en detalle en la Información extendida y previstos por el Reglamento UE 2016/679.</p>
                        <a className='mt-2' href='#Diritti'>
                          + información
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row justify-content-center mt-8'>
                  <div className='col-md-10'>
                    <div className='row text-center'>
                      <div className='col-md-12'>
                        <h2>INFORMACIÓN EXTENDIDA</h2>
                      </div>
                    </div>

                    <div className='row mt-8' id='Titolare'>
                      <div className='col-md-12'>
                        <h4>IDENTIDAD Y DATOS DE CONTACTO DEL RESPONSABLE</h4>
                        <p className=' mt-6'>
                          <b>Identidad</b>: E-leads s.r.l.
                          <br />
                          <b>Dirección</b>: SS Trossi, 41, 13871 Verrone (Bi)
                          <br />
                          <b>Correo electrónico</b>: info@e-leads.it
                          <br />
                        </p>
                      </div>
                    </div>

                    <div className='row mt-3' id='Finalita'>
                      <div className='col-md-12'>
                        <h4>FINALIDAD DEL TRATAMIENTO</h4>
                        <div className=' mt-6'>
                          <ol>
                            <li>
                              <b>Finalidad contractual o de ejecución de medidas precontractuales, incluida la prestación de un servicio a petición del usuario</b>
                              <br />
                              Trataremos tus datos personales para la ejecución de medidas precontractuales, contractuales y de ejecución del servicio. La recopilación de tus datos personales es
                              necesaria para satisfacer las solicitudes y necesidades informativas que hayas manifestado a través de los cuestionarios y formularios puestos a disposición por el
                              Responsable. Podremos llevar a cabo una actividad limitada de perfilado de carácter general, que no implica ningún proceso de toma de decisiones automatizado ni produce
                              efectos jurídicos o incide de manera significativa similarmente sobre el interesado, únicamente con el fin de mejorar la orientación de bienes y servicios ofrecidos por
                              los socios cuando proporcionan respuesta al interesado, incluyendo al interesado en una categoría en función de la información que él mismo nos ha proporcionado y los
                              intereses que él mismo nos ha comunicado (por ejemplo: interés en un determinado producto o servicio).
                            </li>
                            <li>
                              <b>Finalidad de marketing directo, incluso a través de actividades de segmentación de usuarios</b>
                              <br />
                              En caso de que no sea posible utilizar el interés legítimo como base jurídica del tratamiento, solicitaremos tu consentimiento para el tratamiento de datos con fines de
                              marketing directo y en particular para el envío de material publicitario, venta directa, realización de estudios de mercado y comunicación comercial, con sistemas
                              automatizados como correo electrónico, teléfono, sms, mms. Con el fin de programar una oferta adecuada de nuestros servicios, podremos utilizar herramientas automatizadas
                              que permiten segmentar a los usuarios en función del análisis de los datos de tráfico, para enviar publicidad en línea con las preferencias manifestadas por los
                              visitantes durante la navegación en línea. La provisión de datos para fines de marketing es opcional y la falta de provisión no tendrá ninguna consecuencia en la
                              prestación del servicio.
                            </li>
                            <li>
                              <b>Finalidad de marketing indirecto</b>
                              <br />
                              Con el consentimiento previo, los datos personales de los usuarios podrán ser comunicados a Bancos, Intermediarios financieros, Intermediarios inscritos en el Registro
                              Único de Intermediarios, Agentes en actividad financiera, Mediadores crediticios, Compañías aseguradoras, Compañías telefónicas, Empresas de alquiler a largo plazo,
                              Agencias de marketing y publicidad, Empresas proveedoras de servicios públicos (denominados "Terceros") para sus fines promocionales y, en particular, para el envío de
                              material publicitario y/o de venta directa y/o para la realización de investigaciones de mercado y/o de comunicación comercial (análisis de mercado, envío de
                              comunicaciones comerciales por correo electrónico, teléfono, sms, mms).
                              <br />
                              Podrá oponerse en cualquier momento revocando el consentimiento al tratamiento de los datos personales que le conciernen realizado para tal finalidad, poniéndose en
                              contacto con el sujeto tercero en las formas comunicadas en su información.
                            </li>
                            <li>
                              <b>Finalidad de análisis del tráfico y del comportamiento de los usuarios durante la navegación en el sitio</b>
                              <br />
                              Nos reservamos el derecho de monitorear el uso del sitio por parte de los visitantes, registrar información como los movimientos y clics del mouse, las actividades de
                              desplazamiento de la página, información sobre el navegador (tipo, versión, tamaño de pantalla, etc.), información básica del usuario (país, idioma, zona horaria), para
                              mejorar la calidad del sitio y los servicios.
                            </li>
                            <li>
                              <b>Finalidad de verificación del nivel de satisfacción del cliente y mejora del sitio.</b>
                              <br />
                              Podemos contactarlo para detectar su grado de satisfacción en relación con el servicio prestado por el Titular y/o por empresas asociadas. También podemos utilizar
                              entrevistas en línea para detectar el grado de satisfacción en el uso del sitio, recopilando los comentarios de los visitantes. Para proteger su privacidad, la
                              información que ingrese en los formularios en línea se vuelve anónima.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className='fila mt-3' id='Base-giuridica'>
                      <div className='col-md-12'>
                        <h4>BASE JURÍDICA DEL TRATAMIENTO</h4>
                        <div className=' mt-6'>
                          <ol>
                            <li>
                              <b>
                                Tratamiento de datos necesario para la ejecución de un contrato del cual el interesado es parte o para la ejecución de medidas precontractuales adoptadas a solicitud
                                del mismo, incluida la prestación de un servicio.
                              </b>
                              <br />
                              De acuerdo con el Reglamento UE 2016/679, el tratamiento que llevamos a cabo siempre debe tener una base jurídica. El tratamiento de tus datos es necesario para la
                              ejecución de un contrato del cual eres parte, para la ejecución de medidas precontractuales adoptadas a solicitud tuya, para la prestación de un servicio en el marco de
                              una relación contractual y para responder a una solicitud tuya. La falta de suministro implicará la imposibilidad por nuestra parte de prestar los servicios solicitados y
                              la imposibilidad por tu parte de recibir una respuesta a tus solicitudes.
                            </li>
                            <li>
                              <b>Consentimiento</b>
                              <br />
                              También solicitaremos tu consentimiento para ser contactado por nuestros Partners, quienes, una vez recibida la información, podrán comunicarse contigo y proporcionar la
                              información solicitada por ti (información que puede incluir la elaboración de presupuestos, las condiciones contractuales relacionadas con una o más pólizas, las
                              características de los productos de seguros de tu interés). La falta de consentimiento no permitirá que los Partners te contacten para proporcionarte la información
                              solicitada por ti. Puedes revocar el consentimiento en cualquier momento enviando una comunicación al Partner que te contactó, de acuerdo con los procedimientos indicados
                              en su política de privacidad.
                              <br />
                              <br />
                              En caso de que no sea posible utilizar el interés legítimo, solicitaremos tu consentimiento para tratar tus datos personales con fines de marketing directo y en
                              particular para el envío de material publicitario, venta directa, realización de investigaciones de mercado y comunicación comercial, también a través del uso de sistemas
                              automatizados de remarketing con el fin de enviarte comunicaciones en línea con las preferencias que hayas manifestado durante la navegación en línea.
                              <br />
                              Podrás oponerte de inmediato y en cualquier momento a dicho tratamiento enviándonos una comunicación a info@e-leads.it. En cuanto a las comunicaciones comerciales,
                              siempre tendrás la posibilidad de oponerte a dicho tratamiento tanto solicitando no recibir más dichas comunicaciones enviando un correo electrónico a info@e-leads.it o
                              haciendo clic directamente en el enlace de cancelación ("cancelar suscripción") que encontrarás al final de cada correo electrónico recibido. Podrás optar por no
                              participar en la actividad de remarketing y personalización de anuncios a través del siguiente enlace:
                              <a href='https://support.google.com/google-ads/answer/2549063?hl=it' target='_blank'>
                                link
                              </a>
                              . <br />
                              En caso de revocación del consentimiento, el tratamiento de datos para dicho fin cesará inmediatamente. Además, con previo consentimiento, los datos personales podrán ser
                              comunicados a terceros para sus fines comerciales y de marketing (análisis de mercado, envío de comunicaciones comerciales a través de sistemas automatizados). Los
                              terceros que reciban los datos serán titulares autónomos del tratamiento. Para oponerte al tratamiento y revocar el consentimiento, deberás contactar al tercero siguiendo
                              las indicaciones que encontrarás en su política de privacidad.
                            </li>
                            <li>
                              <b>Interés legítimo</b>
                              <br />
                              Nos valdremos del interés legítimo para los fines mencionados en las letras d. y e. del párrafo "FINALIDAD DEL TRATAMIENTO", incluyendo la evaluación de tu grado de
                              satisfacción con respecto a los servicios prestados por el Titular, los Partners o con respecto al uso del Sitio. Por lo tanto, podrás ser contactado telefónicamente o
                              por correo electrónico, sin perjuicio de tu derecho a oponerte a dicho tratamiento en cualquier momento enviándonos una comunicación a info@e-leads.it. Analizaremos el
                              tráfico y el comportamiento de los usuarios durante la navegación en el sitio web para mejorar nuestro servicio y la experiencia de navegación de los visitantes. <br />
                              De conformidad con el artículo 13, párrafo 2, de la Directiva 2009/136/CE, así como en referencia al Considerando (27) del REGULACIÓN 2016/679, podremos utilizar tu
                              dirección de correo electrónico adquirida en el contexto de una relación de cliente entre tú y el Titular (por ejemplo, porque has utilizado nuestros servicios y
                              concluido una transacción a través del sitio) para enviarte comunicaciones electrónicas que tengan por objeto productos o servicios similares a los ofrecidos
                              anteriormente. Desde ahora tienes derecho a rechazar dichas comunicaciones de forma gratuita enviándonos una comunicación a info@e-leads.it y a oponerte en el futuro a
                              dicho tratamiento solicitando la cancelación de tu correo electrónico.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-3' id='Destinatari'>
                      <div className='col-md-12'>
                        <h4>DESTINATARIOS O CATEGORIAS DE DESTINATARIOS</h4>
                        <div className=' mt-6'>
                          <h5>Partners</h5>
                          Comunicaremos a:
                          <ul>
                            <li>
                              Repsol SA
                              <br />
                              Numero de identificación fiscal : A78374725
                              <br />
                              C/ Méndez Álvaro 44, Madrid 28045 (España)
                              <br />
                              Delegado de Protección de Datos: protecciondedatos@repsol.com<br></br>
                              <a href='https://www.repsol.es/particulares/hogar/luz-y-gas/condiciones-potenciales/'> Política de privacidad</a>
                            </li>
                          </ul>
                        </div>

                        {/*  <div className=' mt-6'>
                          <h5>Terceros pertenecientes a las siguientes categorías</h5>
                          Bancos, Intermediarios financieros, Intermediarios inscritos en el R.U.I., Agentes de actividades financieras, Mediadores crediticios, Compañías de seguros, Compañías
                          telefónicas, Sociedades de alquiler a largo plazo, Agencias de marketing y publicidad, Sociedades proveedoras de servicios públicos (para fines promocionales y, en
                          particular, para el envío de material publicitario y/o de venta directa y/o para la realización de estudios de mercado y/o de comunicación comercial, análisis de mercado,
                          envío de comunicaciones comerciales por correo electrónico, teléfono, sms, mms).
                        </div>
 */}
                        <div className=' mt-6'>
                          <h5>Proveedores de servicios de alojamiento.</h5>
                          El servicio de alojamiento del sitio web es gestionado por Digital Ocean LLC, Nueva York, Estados Unidos, (“<b>Encargado del tratamiento</b>”). De acuerdo con el Reglamento
                          UE 2016/679 Reglamento general de protección de datos (“el GDPR”) (Artículo 28, párrafo 3), el Responsable del tratamiento está obligado a suscribir un acuerdo escrito entre
                          el Responsable y cualquier organización que procesa datos personales en su nombre. Por lo tanto, hemos firmado electrónicamente un acuerdo de procesamiento de datos o DPA
                          (Data Processing Agreement) para garantizar el cumplimiento de las obligaciones establecidas por el reglamento. Para conocer las modalidades de tratamiento de datos
                          realizadas por el Encargado del tratamiento, por favor visite la política de privacidad disponible en el siguiente enlace:{" "}
                          <a href='https://www.digitalocean.com/security/gdpr/data-processing-agreement/' target='_blank'>
                            https://www.digitalocean.com/security/gdpr/data-processing-agreement/
                          </a>
                          .<br />
                          Todos los centros de datos utilizados se encuentran en territorios de la UE.
                        </div>
                        <div className=' mt-6'>
                          <h5>Proveedores de servicios de seguimiento y análisis de tráfico web, sistemas automatizados de segmentación de usuarios y marketing.</h5>
                          <ol>
                            <li>
                              Google Inc. con domicilio social en 1600 Amphitheatre Parkway. Mountain View, CA 94043 (“<b>Encargado del tratamiento</b>”). De acuerdo con el Reglamento UE 2016/679
                              Reglamento general de protección de datos (“GDPR”), artículo 28, párrafo 3, el Responsable del tratamiento está obligado a suscribir un acuerdo escrito entre el
                              Responsable y cualquier organización que procesa datos personales en su nombre. Por lo tanto, hemos suscrito un acuerdo de procesamiento de datos con Google Inc. para
                              garantizar el cumplimiento de dichas disposiciones del GDPR en relación con todos los tratamientos de datos personales realizados por el Encargado del tratamiento. El
                              acuerdo se puede encontrar en el siguiente enlace{" "}
                              <a href='https://privacy.google.com/businesses/processorterms/' target='_blank'>
                                https://privacy.google.com/businesses/processorterms/
                              </a>{" "}
                              Para conocer las modalidades de tratamiento de datos realizadas por el Encargado del tratamiento, por favor visite la política de privacidad disponible en el siguiente
                              enlace:{" "}
                              <a href='https://policies.google.com/privacy?hl=policies' target='_blank'>
                                https://policies.google.com/privacy?hl=policies
                              </a>{" "}
                              <br />
                              Puede solicitar que Google Inc no lo rastree desactivando las funciones de Analytics siguiendo las instrucciones e instalando el complemento disponible aquí{" "}
                              <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
                                https://tools.google.com/dlpage/gaoptout
                              </a>
                              . <br />
                              También puede administrar la configuración de anuncios a través de su cuenta de Google siguiendo las instrucciones indicadas aquí:{" "}
                              <a href='https://bit.ly/2nsCU7z' target='_blank'>
                                https://bit.ly/2nsCU7z
                              </a>
                            </li>
                            <li>
                              Hotjar Limited con domicilio social en Level 2, St Julian’s Business Centre, 3, Elia Zammit Street, St Julian’s STJ 1000, Malta (“Encargado del tratamiento”).
                              <br />
                              Para proteger su privacidad, la información que ingresa en los formularios y su dirección IP se enmascaran y se hacen ilegibles antes de que se transmita a Hotjar.
                              <br />
                              De acuerdo con el Reglamento UE 2016/679 Reglamento general de protección de datos (“GDPR”), artículo 28, párrafo 3, el Responsable del tratamiento está obligado a
                              suscribir un acuerdo escrito entre el Responsable y cualquier organización que procesa datos personales en su nombre. Por lo tanto, hemos suscrito un acuerdo de
                              procesamiento de datos con Hotjar Limited para garantizar el cumplimiento de dichas disposiciones del GDPR en relación con todos los tratamientos de datos personales
                              realizados por el Encargado del tratamiento de datos. El acuerdo se puede encontrar en el siguiente enlace.{" "}
                              <a href='https://www.hotjar.com/legal/support/dpa' target='_blank'>
                                https://www.hotjar.com/legal/support/dpa
                              </a>
                              <br />
                              Para conocer los procedimientos de tratamiento de datos realizados por el Responsable del tratamiento, visite la política de privacidad disponible en el siguiente enlace:{" "}
                              <a href='https://www.hotjar.com/legal/policies/privacy' target='_blank'>
                                https://www.hotjar.com/legal/policies/privacy
                              </a>{" "}
                              <br />A pesar de que la información recopilada está anonimizada, el usuario puede solicitar no ser rastreado por Hotjar siguiendo las instrucciones indicadas en el
                              siguiente enlace:{" "}
                              <a href='https://www.hotjar.com/legal/compliance/opt-out' target='_blank'>
                                https://www.hotjar.com/legal/compliance/opt-out
                              </a>{" "}
                              <br />
                            </li>
                            <li>
                              Meta Platforms Ireland Limited, con sede legal en 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Dublin, D02x525, Irlanda (“Responsable del tratamiento”).
                              <br />
                              Para proteger tu privacidad, la información que ingreses en los formularios y tu dirección IP se enmascaran y se vuelven ilegibles antes de que se transmitan a Hotjar.
                              <br />
                              De acuerdo con el Reglamento UE 2016/679 Reglamento general de protección de datos (“GDPR”), artículo 28, párrafo 3, el Responsable del tratamiento debe celebrar un
                              acuerdo escrito entre él y cualquier organización que procese datos personales en su nombre. Por lo tanto, hemos celebrado un acuerdo de procesamiento de datos con Meta
                              Platforms Ireland Limited para garantizar el cumplimiento de las disposiciones del GDPR relacionadas con el tratamiento de datos personales efectuados por el Responsable
                              del tratamiento de datos. El acuerdo se encuentra en el siguiente enlace.{" "}
                              <a href='https://www.facebook.com/legal/terms/dataprocessing' target='_blank'>
                                https://www.facebook.com/legal/terms/dataprocessing
                              </a>
                              <br />
                              Para conocer las formas de tratamiento de datos efectuadas por el Responsable del tratamiento, visite la política de privacidad disponible en el siguiente enlace:{" "}
                              <a href='https://www.facebook.com/about/privacy/' target='_blank'>
                                https://www.facebook.com/about/privacy/
                              </a>{" "}
                              <br />A pesar de que la información recopilada está anonimizada, el usuario puede solicitar no ser rastreado por Hotjar siguiendo las instrucciones indicadas en el
                              siguiente enlace:{" "}
                              <a href='https://m.facebook.com/help' target='_blank'>
                                https://m.facebook.com/help
                              </a>{" "}
                              <br />
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-3' id='Categorie'>
                      <div className='col-md-12'>
                        <h4>CATEGORÍAS DE DATOS PERSONALES TRATADOS</h4>
                        <div className=' mt-6'>
                          <ol>
                            <li>
                              Los sistemas informáticos y los procedimientos de software utilizados para el funcionamiento de este sitio web pueden recopilar automáticamente cierta información, como
                              direcciones IP y otros datos cuya transmisión es implícita en el uso de los protocolos de comunicación de Internet. Estos datos, aunque no se utilizan para identificar a
                              los usuarios, bien podrían permitir la identificación de los usuarios debido a sus características y si se asocian con otros datos (por ejemplo, direcciones IP, nombres
                              de dominio de los ordenadores utilizados por los usuarios que se conectan al sitio, etc.). Estos datos se pueden utilizar tanto para obtener información estadística de
                              forma agregada, como para controlar el correcto funcionamiento del sitio.
                            </li>
                            <li>
                              Para poder proceder con la prestación del servicio, el usuario deberá proporcionar una serie de datos personales, como nombre, apellido, edad, dirección de correo
                              electrónico, dirección de residencia, entre otros.
                            </li>
                            <li>
                              A través de terceros, podemos recopilar información como la ubicación geográfica, el tipo de navegador utilizado, el número de vistas de las páginas, la dirección IP,
                              información sobre los clics realizados en las páginas del sitio. Estos datos se pueden utilizar tanto para obtener información estadística de forma agregada, como para
                              mejorar la experiencia del usuario durante la navegación en el sitio.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-3' id='Trasferimento'>
                      <div className='col-md-12'>
                        <h4>TRANSFERENCIA DE DATOS A PAÍSES TERCEROS</h4>
                        <p className=' mt-6'>
                          Transferencia internacional de datos fuera de la Unión Europea. Todos los destinatarios de los datos establecidos en los Estados Unidos que reciban los datos personales han
                          confirmado el cumplimiento del marco normativo UE-EE. UU. Privacy Shield. La transferencia está autorizada por la siguiente decisión de adecuación aprobada por la Comisión
                          Europea: "<b>Privacy Shield</b>".
                        </p>
                      </div>
                    </div>

                    <div className='row mt-3' id='Conservazione'>
                      <div className='col-md-12'>
                        <h4>CRITERIOS PARA LA CONSERVACIÓN DE DATOS</h4>
                        <p className=' mt-6'>
                          Tus datos personales serán conservados en nuestras bases de datos por un máximo de 5 años a partir de su registro y posteriormente serán eliminados. También serán eliminados
                          tan pronto como nos lo solicites o cuando el tratamiento se base en tu consentimiento, tan pronto como decidas revocarlo, ya sea a través de los enlaces de opt-out presentes
                          en las comunicaciones comerciales recibidas, o enviándonos una comunicación a la siguiente dirección info@e-leads.it
                        </p>
                      </div>
                    </div>

                    <div className='row mt-3' id='Diritti'>
                      <div className='col-md-12'>
                        <h4>DERECHOS DEL INTERESADO</h4>
                        <p className=' mt-6'>Como interesado en el tratamiento, tienes derecho a:</p>
                        <ol>
                          <li>ser informado sobre la existencia o no de datos personales que te conciernen;</li>
                          <li>acceder a los datos personales que están siendo tratados;</li>
                          <li>solicitar la rectificación en caso de que los datos que hayamos recopilado sean inexactos o solicitar la integración de los datos en caso de que sean incompletos;</li>
                          <li>
                            solicitar al Responsable la eliminación de los datos en los casos previstos en el artículo 17 del Reglamento 2016/679, incluyendo en caso de revocación del consentimiento o
                            si los datos personales tratados ya no son necesarios para los fines para los que fueron recopilados o tratados de otra manera;
                          </li>
                          <li>obtener la limitación del tratamiento de acuerdo con el artículo 18 del Reglamento 2016/679;</li>
                          <li>
                            solicitar al Responsable la portabilidad de tus datos personales y recibirlos en un formato estructurado, comúnmente utilizado y legible, o obtener la transmisión directa
                            de tus datos personales a otro Responsable;
                          </li>
                          <li>oponerte en cualquier momento al tratamiento de tus datos;</li>
                          <li>
                            no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos que te afecten o que
                            incidan significativamente en ti de manera similar.
                          </li>
                          <li>presentar una queja ante la Autoridad de Protección de Datos Personales.</li>
                        </ol>
                        <h4 className=' mt-8'>¿CÓMO EJERCER TUS DERECHOS?</h4>
                        <p className=' mt-6'>
                          Puedes ejercer tus derechos en cualquier momento enviándonos un mensaje de correo electrónico a la siguiente dirección: info@e-leads.it
                          <br />
                          Tenemos el deber de responder a tus solicitudes dentro de un mes desde su recepción. Este plazo puede ser prorrogado por dos meses, si es necesario, teniendo en cuenta la
                          complejidad y el número de solicitudes recibidas. En caso de prórroga, se te informará del retraso y las razones.
                          <br />
                          En caso de que consideremos que no podemos dar seguimiento a tus solicitudes, te comunicaremos los motivos del rechazo. En tal caso, tendrás la posibilidad de presentar una
                          queja ante la Autoridad Garante para la protección de los datos personales.
                          <br />
                          <br />
                          Esta información puede sufrir modificaciones. Se invita a los visitantes a verificar regularmente el texto de la política de privacidad.
                        </p>

                        <h4 className=' mt-8'> ¿Cómo puede presentar una reclamación?</h4>
                        <p className=' mt-6'>
                          Además de los derechos que le asisten, si cree que sus datos no se están recabando o tratando conforme a la normativa vigente de Protección de Datos, usted podrá realizar una
                          reclamación ante la Autoridad de Control, cuyos datos de contacto indicamos a continuación: Agencia Española de Protección de Datos C/. Jorge Juan, 6. 28001, Madrid (Madrid),
                          España Email: <a href='maito:info@aepd.es'>info@aepd.es</a> - Teléfono: 912663517 Web: <a href='https://www.aepd.es'>https://www.aepd.es</a>
                        </p>
                      </div>
                    </div>

                    {/*<div className='row text-center'>
                      <div className='col-md-12'>
                        <h2>INFORMACIÓN DE COOKIES</h2>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12'>
                        <h4>¿Qué son las cookies?</h4>
                        <p className=' mt-6'>
                          Las cookies son pequeños archivos de texto que los sitios visitados por los usuarios envían a sus terminales, donde se almacenan para luego ser retransmitidos a los mismos sitios en la visita siguiente. Las cookies pueden ser utilizadas para diferentes finalidades: ejecución de autenticaciones informáticas, seguimiento de sesiones, almacenamiento de información sobre configuraciones específicas de los usuarios que acceden al servidor, almacenamiento de preferencias. El sitio utiliza cookies técnicas, analíticas y de perfilado.
                        </p>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12'>
                        <h4>Cookies técnicas</h4>
                        <p className=' mt-6'>
                          El sitio utiliza cookies necesarias para permitir a los usuarios la correcta navegación en el sitio, permitir el uso de servicios interactivos, facilitar y mejorar la experiencia de los usuarios en nuestro sitio. Algunas funciones de nuestro sitio dependen de las cookies técnicas para funcionar correctamente. Dentro de esta categoría también se encuentran las cookies de funcionalidad, que permiten al usuario navegar en función de una serie de criterios seleccionados (por ejemplo, el idioma, los productos seleccionados para la compra) para mejorar el servicio que se le brinda. Para esta categoría de cookies no se requerirá su consentimiento, aunque podrá desactivarlas en cualquier momento a través de la configuración de su navegador. Al desactivar las cookies técnicas, no podemos garantizarle un uso del sitio sin errores.
                        </p>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12'>
                        <h4>Cookies de análisis</h4>
                        <p className=' mt-6'>
                          El sitio también utiliza cookies de Google Analytics para recopilar información de forma agregada sobre el número de usuarios y cómo los usuarios utilizan el sitio. Se trata de un servicio de análisis web proporcionado por Google. La información generada se utiliza para indicar las tendencias de los usuarios en este sitio web sin identificar a los visitantes individuales y para generar informes sobre el uso del sitio en sí. Los datos generados por Google Analytics son almacenados por Google según se indica en la información disponible en el siguiente enlace:{" "}
                          <a href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage' target='_blank'>
                            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                          </a>
                          . Para consultar la política de privacidad de la compañía Google Inc., se remite al sitio web{" "}
                          <a href='http://www.google.com/intl/en/analytics/privacyoverview.html' target='_blank'>
                            http://www.google.com/intl/en/analytics/privacyoverview.html
                          </a>
                          . El titular ha aceptado los "Términos contractuales para el tratamiento de los datos de los Anuncios de Google" puestos a disposición por Google Inc. en cumplimiento del Reglamento 2016/679 y que definen algunos aspectos sobre el procesamiento y la seguridad de los datos personales de los usuarios en conformidad con la normativa sobre protección de datos. Los usuarios que no deseen que sus datos sean utilizados por Google Analytics, pueden instalar{" "}
                          <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
                            el componente adicional del navegador para la desactivación de Google Analytics
                          </a>
                          .Este componente adicional indica al JavaScript de Google Analytics (ga.js, analytics.js y dc.js) en sitios web que no utilicen los datos de Google Analytics.                        </p>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12'>
                        <h4>Cookies de perfilado, marketing, publicidad y seguimiento</h4>
                        <p className=' mt-6'>
                          Las cookies de perfilado se utilizan para recibir ofertas construidas a partir de las preferencias de compra
                          y los gustos manifestados por los usuarios durante la navegación en línea y así mostrarles mensajes publicitarios coherentes
                          con su perfil. De esta manera, los mensajes publicitarios que verá en el sitio pueden ser más acordes con sus preferencias.
                          Las cookies de perfilado también incluyen aquellas aptas para realizar el seguimiento de las elecciones del usuario durante la navegación.
                          El usuario deberá dar su consentimiento para la instalación de las cookies de seguimiento y, de todos modos, será libre de
                          bloquear la instalación de las cookies de perfilado en cualquier momento, sin que esto tenga ninguna consecuencia en su
                          capacidad para visitar el sitio y disfrutar de sus contenidos.
                          Si el usuario decide desactivar las cookies de perfilado, los banners publicitarios y los anuncios eventualmente visualizados en los sitios podrían no reflejar los intereses o las preferencias del usuario.
                          <br />
                          <br />
                          A continuación se muestra una lista de cookies de remarketing, análisis y seguimiento instaladas a través del sitio:
                          <div id='cookies'></div>
                        </p>
                      </div>
                    </div>

                    <div className='row mt-8'>
                      <div className='col-md-12'>
                        <h4>¿Cómo habilitar o deshabilitar las cookies a través del navegador?</h4>
                        <p className=' mt-6'>
                          En caso de que el usuario decida no permitir la instalación de cookies de navegación o técnicas en su computadora, algunas funciones y servicios podrían no estar disponibles. Las cookies técnicas no registran ninguna información personal sobre un usuario y los posibles datos identificables no se almacenarán. Si el usuario decide optar por el bloqueo del almacenamiento de cookies técnicas, el titular ya no podrá garantizar un correcto funcionamiento del sitio. Sin perjuicio de lo indicado en relación con las cookies necesarias para la navegación, el usuario puede evitar la instalación de cookies de análisis, marketing y seguimiento a través de la configuración del banner o eliminarlos posteriormente a través de su navegador. Cada navegador web permite limitar y eliminar las cookies a través de la configuración. Para obtener más información sobre la gestión de cookies, consulte el siguiente enlace relevante. <br />
                          <a href='https://privacy.microsoft.com/en-us/privacystatement' target='_blank'>
                            Internet Explorer
                          </a>
                          <br />
                          <a href='https://support.mozilla.org/it/kb/Gestione%20dei%20cookie?redirectlocale=en-US&redirectslug=Cookies' target='_blank'>
                            Firefox
                          </a>
                          <br />
                          <a href='https://support.google.com/chrome/answer/95647?hl=it' target='_blank'>
                            Chrome
                          </a>
                          <br />
                          <a href='https://support.apple.com/kb/PH5042?locale=en_US' target='_blank'>
                            Safari
                          </a>
                          <br />
                          El titular informa que es posible utilizar el servicio Your Online Choices y modificar la configuración de cookies según sus preferencias{" "}
                          <a href='http://www.youronlinechoices.com/it ' target='_blank'>
                            http://www.youronlinechoices.com/it{" "}
                          </a>
                        </p>
                      </div>
                    </div>*/}
                  </div>
                </div>
                <div className='row mt-8'>
                  <div className='col-md-12 text-right'>
                    <p className=' mt-6'>
                      <i>Última actualización: [15 de abril de 2022]</i>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </Layout>
      </Page>
    );
  }
}
